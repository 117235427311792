import React, { FunctionComponent } from 'react';
import { ImageFieldsFragment } from '../../../../__generated__/graphql-client-types';
import { SMALL_MAX_PX, MEDIUM_MAX_PX, MAX_PAGE_WIDTH } from '../../../../constants/breakpoints';
import { Variation } from '../../../../helpers/images/images.helper';
import { CloudinaryImage } from '../../cloudinary-image/cloudinary-image.component';

export type FullWidthGraphImageProps = {
	image: ImageFieldsFragment;
};

export const FullWidthGraphImage: FunctionComponent<FullWidthGraphImageProps> = ({ image: { description, id, imageType } }) => {
	const variations: Variation[] = [
		{ mediaCondition: { maxWidth: SMALL_MAX_PX }, options: { width: SMALL_MAX_PX } },
		{ mediaCondition: { maxWidth: MEDIUM_MAX_PX }, options: { width: MEDIUM_MAX_PX } }
	];
	return (
		<CloudinaryImage
			publicID={id}
			options={{ width: MAX_PAGE_WIDTH, type: imageType }}
			variations={variations}
			description={description}
		/>
	);
};
