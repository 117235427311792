import React from 'react';
import { CloudinaryOptions, generateCloudinaryUrl } from '../../../../helpers/cloudinary-helper/cloudinary-helper';
import { ContentComponent, RelatedArticleContent } from '../../../../types/construct.types';
import { Link } from '../../../common-components/link/link.component';
import { addContentHandler } from '../add-content-handler';
import { relatedArticleOverlay, relatedArticleBgImg } from './related-article.css';

const imageOptions: CloudinaryOptions = {
	width: 456,
	height: 274,
	crop: 'lfill'
};

export const RelatedArticle: ContentComponent<RelatedArticleContent> = ({ content }) => {
	const cloudinaryUrl = generateCloudinaryUrl(content.fields.image.image?.id || '', {
		...imageOptions,
		type: content.fields.image.image?.imageType
	});
	return (
		<div className="w-100 h-100">
			<Link url={content.fields.url.linkUrl} className="db">
				<figure className="w-100 h-100 relative ma0">
					<div
						style={{
							backgroundImage: `url(${cloudinaryUrl})`
						}}
						className={`bg-theme-grey-light bg-center cover overflow-hidden relative ${relatedArticleBgImg}`}
					/>
					<figcaption
						className={`flex flex-column f6 lh-copy bg-theme-white ba b--theme-grey-light bottom-0 cb overflow-hidden pv2 ph3 static absolute-ns w-80-ns ${relatedArticleOverlay}`}>
						<strong className="theme-black">{content.fields.title}</strong>
						<p className="ma0 theme-primary underline-hover">Read Article</p>
					</figcaption>
				</figure>
			</Link>
		</div>
	);
};

addContentHandler('related-article', 'ObjectContent', 'related-article', RelatedArticle);
