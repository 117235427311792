import React from 'react';
import { ContentComponent, SpecialPricingFormPlaceholderContent } from '../../../../types/construct.types';
import { ContactUsForm } from '../../../support-components/contact-us-form/contact-us-form.component';
import { addContentHandler } from '../add-content-handler';

export const SpecialPricingFormPlaceholder: ContentComponent<SpecialPricingFormPlaceholderContent> = () => (
	<ContactUsForm isSpecialPricing={true} />
);

addContentHandler('content-support-pages', 'ObjectContent', 'placeholder-special-pricing-form', SpecialPricingFormPlaceholder);
