import React, { FunctionComponent, PropsWithChildren } from 'react';
import { noLabelPadding } from './placeholder.css';

export type PlaceholderProps = {
	label?: string;
	width?: number;
	height?: number;
	className?: string;
};

export const Placeholder: FunctionComponent<PropsWithChildren<PlaceholderProps>> = ({ label, width, height, className, children }) => (
	<section
		className={`${label ? 'pa2' : noLabelPadding} theme-internal-dark ba b--theme-internal bg-theme-internal-lighter flex
			justify-center items-center align-center ${className || ''}`}
		style={{ width, height }}>
		{label && <h1>{label}</h1>}
		{children}
	</section>
);
