import { ContentComponent, TypedContent } from '../../../types/construct.types';

type RenderableType = Exclude<TypedContent['type'], 'LinkContent'>;

// TODO: SODEV-38481 auto-generate bundles based on the bundle value... for each use of AddContentHandler, add the given function to
//   the given bundle. Then generate bundles. Similar to how routes are generated...
export function addContentHandler<T extends TypedContent>(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	bundle: string,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	type: RenderableType,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	kind: string,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	fn: ContentComponent<T>
) {}
