import { useMutation } from '@apollo/client';
import { logError } from 'fergy-core-react-logging';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import {
	EmailOriginationEnum,
	SubscribeToNewsletterMutation,
	SubscribeToNewsletterMutationVariables
} from '../../__generated__/graphql-client-types';
import { SUCCESS_SUBSCRIBED, ERROR_SUBSCRIBE } from '../../constants/message';
import { emailInputRegistrationConfig } from '../../helpers/form-validation-helper/form-validation-helper';
import { useNotification } from '../../hooks/notification/notification.hooks';
import { SUBSCRIBE_TO_NEWSLETTER } from '../../queries/newsletter/newsletter.queries';
import { StyledButton } from '../buttons';
import { TextInput } from '../inputs';
import { InputGroup } from '../inputs/input-group/input-group.component';
import { ReportIcon } from '../svg/icons.component';

type FormInputs = {
	email: string;
};

export type EmailSignUpFormProps = {
	origin?: EmailOriginationEnum;
};

export const EmailSignUpForm: FunctionComponent<EmailSignUpFormProps> = ({ origin = 'NONE' }) => {
	const { notifySuccess, notifyWarning } = useNotification();
	const [subscribeToNewsletter] = useMutation<SubscribeToNewsletterMutation, SubscribeToNewsletterMutationVariables>(
		SUBSCRIBE_TO_NEWSLETTER
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm<FormInputs>();

	/**
	 * subscribe to the newsletter and display the response. Alert if no email was provided
	 */
	const onSubmit = (formData: FormInputs) => {
		subscribeToNewsletter({
			variables: {
				input: {
					email: formData.email,
					origin
				}
			}
		})
			.then((result) => {
				notifySuccess(result?.data?.subscribeToNewsletter || SUCCESS_SUBSCRIBED);
			})
			.catch((error) => {
				logError(error);
				notifyWarning(ERROR_SUBSCRIBE, 3000);
			})
			.finally(reset);
	};

	return (
		<div>
			<h2 className="b db f5 mt0 mb3">Sign up to get the best deals</h2>
			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-column" aria-label="newsletter signup form">
				<InputGroup className="w-100">
					<TextInput
						ariaLabel="email"
						placeholder="Enter Email Address"
						autoCapitalize={'off'}
						{...register('email', emailInputRegistrationConfig)}
					/>
					<StyledButton buttonType="submit">Subscribe</StyledButton>
				</InputGroup>
				{/* Input Group doesn't support form invalid state */}
				{errors.email && (
					<div className={`flex flex-row items-center mt3 f6 theme-error gc1`}>
						<ReportIcon className="f5 minw1" />
						{errors.email?.message}
					</div>
				)}
			</form>
		</div>
	);
};
