import React, { FunctionComponent } from 'react';
import { ImageFieldsFragment } from '../../../../__generated__/graphql-client-types';
import { CloudinaryImage } from '../../cloudinary-image/cloudinary-image.component';

export type ProfileGraphImageProps = {
	image: ImageFieldsFragment;
	size?: number;
};

export const ProfileGraphImage: FunctionComponent<ProfileGraphImageProps> = ({
	image: { imageType, id: publicID, description },
	size = 128
}) => (
	<CloudinaryImage
		options={{
			type: imageType,
			width: size,
			height: size,
			radius: 'max',
			crop: 'fill',
			gravity: 'face'
		}}
		bypassLazyLoad={true}
		publicID={publicID || 'mediabase/build_profiles/profiledefault/profiledefault250.png'}
		description={description || 'default avatar'}
	/>
);
