import React from 'react';
import { ObjectContent, ListContentOf, CardContent, ContentComponent, TextContent } from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';

export interface CategorySectionContent extends ObjectContent {
	fields: {
		group_items_heading: TextContent;
		group_items: ListContentOf<CardContent>;
	};
}

export const CategorySection: ContentComponent<CategorySectionContent> = ({ content, renderer }) => {
	const groupItems = content.fields.group_items;
	return (
		groupItems.items.length > 0 && (
			<div className="w-100">
				<h4 className="tc-ns">{content.fields.group_items_heading}</h4>
				<div className="flex flex-wrap items-start">{renderer.render(groupItems)}</div>
			</div>
		)
	);
};

addContentHandler('category-section', 'ObjectContent', 'category-section', CategorySection);
