import React, { FunctionComponent } from 'react';
import { ContentComponent, RichTextContent, RichTextSectionContent } from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';

export type RichTextProps = {
	rawHtml: string;
	className?: string;
};

export const RichText: FunctionComponent<RichTextProps> = ({ rawHtml, className = '' }) => {
	if (rawHtml === '<p></p>') return null;
	return <div data-testid="richTextContent" className={`w-100 ${className} rich-text`} dangerouslySetInnerHTML={{ __html: rawHtml }} />;
};

export const RichTextHandler: ContentComponent<RichTextContent> = ({ content, options }) => {
	return <RichText className={options?.RichTextContent?.className ?? 'lh-copy'} rawHtml={content.richText} />;
};

export const RichTextSectionHandler: ContentComponent<RichTextSectionContent> = ({ content }) => {
	return <RichText rawHtml={content.fields.body_copy.richText} />;
};
addContentHandler('content-common', 'RichTextContent', 'default', RichTextHandler);
