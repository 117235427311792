import React from 'react';
import { ContentComponent, CallToActionSectionContent } from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';
import { CallToAction } from '../call-to-action/call-to-action.handler';

export const CallToActionSection: ContentComponent<CallToActionSectionContent> = (props) => {
	const {
		content: {
			fields: { cta_section_heading, cta }
		}
	} = props;

	return (
		cta && (
			<div className={'w-100 tc pv5 pv7-ns'}>
				{cta_section_heading && <h2 className="mb5">{cta_section_heading}</h2>}
				<CallToAction {...props} />
			</div>
		)
	);
};

addContentHandler('content-common', 'ObjectContent', 'cta-section', CallToAction);
