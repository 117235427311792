import React, { FunctionComponent } from 'react';
import { TypedContentOptions, ContentComponent } from '../../../../types/construct.types';
import { MaybeLink } from '../../../common-components/link/link.component';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { addContentHandler } from '../add-content-handler';
import { MediaCardOptions, mediaCardsOptions } from '../media-card/media-card-options';
import { CardContentRich } from '../media-group/media-group.handler';
import { RichText } from '../rich-text/rich-text.handler';

const RichMediaCardImageTitleDescription: FunctionComponent<RichMediaCardProps> = ({ content, options }) => {
	const displayInfo: MediaCardOptions = mediaCardsOptions[options?.CardContent?.layout ?? 'hero'];

	const image = content.image?.id && (
		<CloudinaryImage publicID={content.image.id} description={content.image.description} options={displayInfo.optionsTall} />
	);

	return image ? (
		<div className={`${displayInfo.className} ${displayInfo.mediaClassName}`} data-testid="rich-media-card">
			<MaybeLink url={content.url}>{image}</MaybeLink>
			{content.cardFields.richDescription?.richText && (
				<RichText className="mt3 tc" rawHtml={content.cardFields.richDescription.richText} />
			)}
		</div>
	) : null;
};

export type RichMediaCardProps = {
	content: CardContentRich;
	options?: TypedContentOptions;
};

const RichMediaBulletImageTitleDescription: FunctionComponent<RichMediaCardProps> = ({ content, options }) => {
	const info: MediaCardOptions = mediaCardsOptions[options?.CardContent?.layout ?? 'hero'];
	const imageId = content.image?.id;
	const imageDescription = content.image?.description ?? 'No image';

	return (
		<div className={`flex items-start ${info.className}`} data-testid="rich-media-card-bullet">
			<MaybeLink url={content.url}>
				<CloudinaryImage className="w-25" publicID={imageId} description={imageDescription} options={info.optionsTall} />
			</MaybeLink>
			<div className="pl4 w-75">
				{content.cardFields.richDescription?.richText && <RichText rawHtml={content.cardFields.richDescription.richText} />}
			</div>
		</div>
	);
};

export const RichMediaCard: ContentComponent<CardContentRich> = ({ content, options }) => {
	const itemArrangement = options?.CardContent?.itemArrangement ?? 'card-image-title-description';
	if (itemArrangement === 'card-image-title-description' || itemArrangement === 'hero') {
		return <RichMediaCardImageTitleDescription content={content} options={options} />;
	}
	if (itemArrangement === 'bullet-image-title-description') {
		return <RichMediaBulletImageTitleDescription content={content} options={options} />;
	}
	return null;
};

addContentHandler('content-media-group', 'ObjectContent', 'group-item-rich', RichMediaCard);
