import React, { FunctionComponent, PropsWithChildren } from 'react';
import { NO_IMAGE_URL } from '../../../constants/images';
import { generateCloudinaryUrl } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { CloudinaryImageProps } from '../cloudinary-image/cloudinary-image.component';

export type BackgroundImageProps = PropsWithChildren<Omit<CloudinaryImageProps, 'description'>>;
export const BackgroundImage: FunctionComponent<BackgroundImageProps> = ({
	children,
	className = '',
	options,
	publicID = NO_IMAGE_URL
}) => {
	const primaryOptions = { ...options, type: options?.type || 'private' };
	const source = generateCloudinaryUrl(publicID, primaryOptions);
	const hasAspectRatio = className.includes('aspect-ratio');

	return (
		<div className={className} style={{ backgroundImage: `url(${source})` }} data-testid="background-image">
			{hasAspectRatio ? <div className="aspect-ratio--object">{children}</div> : <>{children}</>}
		</div>
	);
};
