import React from 'react';
import { ContentComponent, HeroMediaObject } from '../../../../types/construct.types';
import { EmbeddedVideo } from '../../../common-components/embedded-video/embedded-video.component';
import { FullWidthGraphImage } from '../../../images/graph-images/full-width-graph-image/full-width-graph-image.component';
import { addContentHandler } from '../add-content-handler';

export const HeroMediaHandler: ContentComponent<HeroMediaObject> = ({
	content: {
		fields: { media_image, media_video }
	}
}) => {
	if (media_image?.image) {
		return <FullWidthGraphImage image={media_image.image} />;
	} else if (media_video?.items[0]?.detail.video) {
		return <EmbeddedVideo video={media_video.items[0].detail.video} />;
	}
	return null;
};

addContentHandler('hero-media', 'ObjectContent', 'here-media@1', HeroMediaHandler);
