import React from 'react';
import { ContentComponent, PullQuoteSectionContent } from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';
import { RichText } from '../rich-text/rich-text.handler';

export const PullQuoteSection: ContentComponent<PullQuoteSectionContent> = ({ content }) => {
	return <RichText className="w-auto w-100-ns ma5 tc f3 fw4 i" rawHtml={content.fields.body_copy.richText} />;
};

addContentHandler('pull-quote-section', 'ObjectContent', 'pull-quote-section@1', PullQuoteSection);
