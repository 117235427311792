import React, { FunctionComponent } from 'react';
import { ContentCardCommonFieldsFragment } from '../../../__generated__/graphql-client-types';
import { SMALL_MAX_PX } from '../../../constants/breakpoints';
import { Variation } from '../../../helpers/images/images.helper';
import { Link } from '../../common-components/link/link.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { ChevronRightIcon } from '../../svg/icons.component';
import { flexBasis25NotSmall } from './category-content-card.css';
export type CategoryContentCardProps = ContentCardCommonFieldsFragment;

export const CategoryContentCard: FunctionComponent<CategoryContentCardProps> = ({ title, image, url }) => {
	const description = image?.description || title || '';
	const variations: Variation[] = [{ mediaCondition: { maxWidth: SMALL_MAX_PX }, options: { width: 45, height: 45 } }];
	return url ? (
		<div className={`${flexBasis25NotSmall} ph2-ns dib-ns`}>
			<Link url={url} color="grey-darker">
				<section className="bg-theme-white ph3 pa3-ns flex justify-between items-center ba-ns b--dotted-ns b--theme-grey-light">
					<div className="flex flex-column-ns w-100-ns items-center gc2">
						{image ? (
							<CloudinaryImage
								options={{ width: 182, height: 182 }}
								variations={variations}
								useDimensions={false}
								publicID={image.id}
								description={description}
							/>
						) : null}
						{title && <h5 className="f5 normal tc ma0 theme-dark-grey-ns h2-ns">{title}</h5>}
					</div>
					<ChevronRightIcon className="dn-ns theme-grey-dark" />
				</section>
			</Link>
		</div>
	) : null;
};
