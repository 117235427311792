import { SeoMetaData, SeoMetaDataContent } from '../../types/construct.types';

export function getSeoMetaData(seo: SeoMetaDataContent): SeoMetaData {
	return {
		title: seo.fields.meta_title ?? '',
		description: seo.fields.meta_description ?? '',
		isIndexed: seo.fields.indexed === 'true',
		socialMedia: {
			image: seo.fields.social_media_image?.image ?? undefined,
			title: seo.fields.social_media_title ?? '',
			description: seo.fields.social_media_description ?? ''
		}
	};
}
