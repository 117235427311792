import React from 'react';
import { ContentComponent, SiteWideSaleSection } from '../../../../types/construct.types';
import { addContentHandler } from '../add-content-handler';

export const SiteWideSaleSectionHandler: ContentComponent<SiteWideSaleSection> = ({
	content: {
		fields: { heading, related_categories }
	},
	renderer
}) => {
	return related_categories.items.length ? (
		<section className="w-100">
			{heading && <h2 className="tc">{heading}</h2>}
			{renderer.render(related_categories, undefined, { ListContent: { totalItemCount: related_categories.items.length } })}
		</section>
	) : null;
};

addContentHandler('sitewide-sales-template', 'ObjectContent', 'sitewide-sale-section@1', SiteWideSaleSectionHandler);
