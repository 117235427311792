import React, { FunctionComponent } from 'react';
import { notNull } from '../../../../utils/type/type.utils';
import { SMALL_MAX_PX } from '../../../constants/breakpoints';
import { Variation } from '../../../helpers/images/images.helper';
import { CardContent } from '../../../types/construct.types';
import { LinkButton } from '../../buttons/link-button/link-button.component';
import { MaybeLink } from '../../common-components/link/link.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { ChevronRightIcon } from '../../svg/icons.component';
import { flexBasisCard, cardMinWidth, infoWrapper } from './sale-category-content-card.css';

export type SaleCategoryContentCardProps = CardContent;

export const SaleCategoryContentCard: FunctionComponent<SaleCategoryContentCardProps> = ({
	image,
	title,
	url,
	cardFields,
	trackingCode
}) => {
	const description = image?.description || title || '';
	const variations: Variation[] = [{ mediaCondition: { maxWidth: SMALL_MAX_PX }, options: { width: 45, height: 45 } }];
	return (
		<div className={`${flexBasisCard} ${cardMinWidth} ph2-ns dib-ns`}>
			<MaybeLink url={url} analyticsHook={notNull(trackingCode)}>
				<section className="bg-theme-white ph3 pa3-ns pb3 flex justify-between items-center ba-ns bb b--theme-grey-light">
					<div className="flex flex-column-ns w-100 items-center gc2">
						{image && (
							<CloudinaryImage
								options={{ width: 182, height: 182 }}
								variations={variations}
								useDimensions={false}
								publicID={image.id}
								description={description}
							/>
						)}
						<div className={`w-100 ${infoWrapper}`}>
							{title && (
								<p className={`w-100 f6 normal tc-ns ma0 mb1-ns fw7-ns truncate w-90 pt2-ns theme-grey-darker`}>{title}</p>
							)}
							{cardFields.percent_off && (
								<p className="f5 tc ma0 theme-emphasis mb1 dn db-ns">Save up to {String(cardFields.percent_off)}%</p>
							)}
						</div>
						<div className="dn flex-ns w-100 pa1">
							{url && (
								<LinkButton url={url} buttonStyle="SECONDARY">
									Save Now
								</LinkButton>
							)}
						</div>
					</div>
					<ChevronRightIcon className="dn-ns theme-grey" />
				</section>
			</MaybeLink>
		</div>
	);
};
