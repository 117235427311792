import React, { FunctionComponent } from 'react';
import { CloudinaryOptions } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { BackgroundImageProps, BackgroundImage } from '../background-image/background-image.component';

export type MaybeBackgroundImageProps = Omit<BackgroundImageProps, 'publicID' | 'options'> & {
	publicID?: string;
	options?: CloudinaryOptions;
};

export const MaybeBackgroundImage: FunctionComponent<MaybeBackgroundImageProps> = ({ publicID, options = {}, children, ...rest }) => {
	return publicID ? (
		<BackgroundImage publicID={publicID} options={options} {...rest}>
			{children}
		</BackgroundImage>
	) : (
		<>{children}</>
	);
};
