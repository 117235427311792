import React, { FunctionComponent } from 'react';
import { CardContent, ContentComponent, TypedContentOptions } from '../../../../types/construct.types';
import { EmbeddedVideo } from '../../../common-components/embedded-video/embedded-video.component';
import { MaybeLink } from '../../../common-components/link/link.component';
import { CloudinaryImage } from '../../../images/cloudinary-image/cloudinary-image.component';
import { ContentComponentHelper } from '../../render-helper/render.helper';
import { addContentHandler } from '../add-content-handler';
import { MediaCardOptions, mediaCardsInfo } from './media-card-options';

export type MediaCardProps = {
	content: CardContent;
	options?: TypedContentOptions;
	renderer?: ContentComponentHelper<CardContent>;
};

const ImageMediaCardImageTitleDescription: FunctionComponent<MediaCardProps> = ({ content, options, renderer }) => {
	const schemaType = renderer?.schemaType;
	const info: MediaCardOptions = mediaCardsInfo(schemaType, options?.CardContent?.layout);
	const imageId = content.image?.id;
	const imageDescription = content.image?.description ?? 'No image';

	return (
		<div className={info.className} data-testid="image-media-card">
			<div className={info.mediaClassName}>
				<MaybeLink url={content.url}>
					<CloudinaryImage publicID={imageId} description={imageDescription} options={info.optionsTall} className="w-100" />
				</MaybeLink>
			</div>
			{content.title && <div className="tc fw6-ns mt1">{content.title}</div>}
			{content.description && <div className="tc mt1">{content.description}</div>}
		</div>
	);
};

const ImageMediaBulletImageTitleDescription: FunctionComponent<MediaCardProps> = ({ content, options, renderer }) => {
	const schemaType = renderer?.schemaType;
	const info: MediaCardOptions = mediaCardsInfo(schemaType, options?.CardContent?.layout);
	const imageId = content.image?.id;
	const imageDescription = content.image?.description ?? 'No image';
	return (
		<div className={`flex items-start items-center ${info.className}`} data-testid="image-media-card-bullet">
			<div className="w-25">
				<MaybeLink url={content.url}>
					<CloudinaryImage publicID={imageId} description={imageDescription} options={info.optionsTall} />
				</MaybeLink>
			</div>
			<div className="w-75 pl4">
				{content.title && (
					<MaybeLink url={content.url} underlineHover={true}>
						<div className="f4 fw6-ns mt1">{content.title}</div>
					</MaybeLink>
				)}
				{content.description && <div className="mt1 f6">{content.description}</div>}
			</div>
		</div>
	);
};

const ImageMediaCard: FunctionComponent<MediaCardProps> = ({ content, options, renderer }) => {
	const itemArrangement = options?.CardContent?.itemArrangement ?? 'card-image-title-description';
	if (itemArrangement === 'card-image-title-description' || itemArrangement === 'hero') {
		return <ImageMediaCardImageTitleDescription content={content} options={options} renderer={renderer} />;
	}
	if (itemArrangement === 'bullet-image-title-description') {
		return <ImageMediaBulletImageTitleDescription content={content} options={options} />;
	}
	return null;
};

const VideoMediaCard: FunctionComponent<MediaCardProps> = ({ content, options, renderer }) => {
	const schemaType = renderer?.schemaType;
	const info: MediaCardOptions = mediaCardsInfo(schemaType, options?.CardContent?.layout);
	const detail = content.detail;

	return detail && 'video' in detail && detail.video ? (
		<div className={info.className}>
			<EmbeddedVideo video={detail.video} />
			{content.title && <div className="tc fw6-ns mt1">{content.title}</div>}
			{content.description && <div className="tc mt1">{content.description}</div>}
		</div>
	) : null;
};

export const MediaCard: ContentComponent<CardContent> = ({ content, options, renderer }) => {
	if (content.cardType === 'IMAGE') {
		return <ImageMediaCard options={options} content={content} renderer={renderer} />;
	}
	if (content.cardType === 'VIDEO') {
		return <VideoMediaCard options={options} content={content} renderer={renderer} />;
	}
	return null;
};

addContentHandler('content-media-group', 'ObjectContent', 'group-item', MediaCard);
